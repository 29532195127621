.Checkbox > span:first-of-type {
  background-color: #DDE6EB;
}

.Checkbox > input {
  left: -99999px;
}

.Checkbox input:checked + span svg {
  opacity: 1;
}

.Checkbox > input:disabled + span {
  cursor: not-allowed;
  opacity: 0.5;
}

.Checkbox input:focus + span {
  outline: 1px solid #bbb;
}
