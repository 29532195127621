.ComponentLoader { animation: fadeCube 4s ease-in-out infinite both;}

.ComponentLoader .shape {
  stroke-dashoffset: 4187;
  animation: cube 4s ease-in-out infinite both;
}

.ComponentLoader path:not(.shape) {
  opacity: 0;
  animation: fadePaths 4s ease-in-out infinite both;
}


@keyframes cube {
  0% { 
    stroke-dashoffset: 4187
  }
  50% {
    stroke-dashoffset: 0
  }
  100% {
    stroke-dashoffset: 0
  }
}

@keyframes fadePaths {
  0% { opacity: 0; }
  40% { opacity: 0; }
  60% { opacity: 1; }
  100% { opacity: 1; }
}

@keyframes fadeCube {
  60% { opacity: 1; }
  75% { opacity: 0; }
  100% { opacity: 0; }
}
