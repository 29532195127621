.navbar-item-active {
  border-bottom: 5px solid rgba(201, 210, 215, .4);
}

@media only screen and (min-width: 60em) {
  .navbar-container {
    width: 9.25rem;
  }
  .navbar-item-active {
    border-left: 5px solid rgba(201, 210, 215, .4);
    border-bottom: 0;
  }
}

@media only screen and (min-height: 49em) and (min-width: 60em) {
  .navbar-logo-vert {
    padding-bottom: 1rem;
  }
  .navbar-item {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-footer {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@media only screen and (max-height: 40em) {
  .navbar-footer {
    display: none;
  }
}
