@import './reset.css';
@import "../node_modules/tachyons";
/* They forgot to include word break: https://github.com/tachyons-css/tachyons/issues/563 */
@import "../node_modules/tachyons/src/_word-break.css";
@import "../node_modules/ipfs-css";

body {
  overflow-y: scroll;
}

.placeholder-light::placeholder{
  color: #CAD3D8;
}

.bg-near-white {
  background-color: #fbfbfb;
}

html, body, #root {
  min-height: 100%;
}

.appOverlay {
  width: 100%;
}

@media only screen and (min-width: 60em) {
  .appOverlay {
      width: calc(100% - 148px)
  }
}

/* =========================
    React Joyride overrides
   ========================= */

.react-joyride__tooltip button {
  font-size: 14px !important;
  font-family: 'Montserrat';
}
